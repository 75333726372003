/* eslint-disable no-console,no-undef, camelcase */
import styled, { css } from 'styled-components';
import { ImageContainer } from '../PeopleListView/styles';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  float: left;
  margin-top: ${({marginTop}) => marginTop ? marginTop :'10px'};
  display: flex;
  flex-wrap: wrap;
  align-items: inherit;
  margin-right: -13px;
  display: flex;
  justify-content: ${({content}) => content ? 'flex-start' :'space-between'};
`;

const CardContainer = styled.div`
      width: 266px;
      height: 330px;
      border-radius: 6px;
  padding: 0;
  min-height: 300px;
  text-align: center;
  border-radius: 5px; 
  background-color: ${({newDeleted}) => newDeleted ? '#FFC0CB' :'#fff'};
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 30px) / 3);
  .headerGrid {
    margin-bottom: 0px;
    padding: 12px 10px 0px;
  }
  & > div{
    text-align: none;
    width: 100%;
  }
  
  ${({ active }) => active && css`
    filter: drop-shadow(5px 4px 10px rgba(58, 58, 58, 0.2));
    
    `}
    
  background-color: ${({deleted}) => deleted && 'none'};
  
  @media (max-width: 1205px){
    width: calc((100% - 26px) / 2);
    float: left;
  }
  
  @media (max-width: 760px) {
    width: calc((100% - 26px) / 2);
    float: left;
  } 

  @media (max-width: 480px) {
    width: 100%;
  }
  .section2 {
     width: calc(100% - 10px);
    font-family: Rubik;
    font-style: normal;
   font-weight: normal;
   font-size: 16px;
   text-align: center;
   color: #005C87;
   padding-top: 10px;
    } 
 
`;

const ImageWrapper = styled(ImageContainer)`
  float: none;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  margin: 10px auto;
  position: relative;
  top: 30px;
  top:  ${({ top }) => top ? '30px' : '30px'};
  > img {
    width: 100%;
    margin-top: 0px;
    border-radius: 6px;
  }
`;
const NameContainer = styled.div`
  margin-top: 13px;
  padding: 0px 10px;
`;

const EmployeeInformation = styled.div`
  margin: 0;
  display: inline-block;
`;

const InformationWrapper = styled.div`
  margin-top: ${({ active  }) => !active && '10px'};
  float: left;
  width: 100%;
  text-align: center;
  > span {
    font-size: 13px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
  }

  > div {
    color: rgb(153,153,153);
    font-size: 15px;
    margin-bottom: 5px;
  }
  ${({ boldFont }) => boldFont & css`
    > div {
      font-family: ${BoldFont};
    }
  `}
`;

const LevelText = styled.div`
  float: left;
  width: 100%;
  position: relative;
  bottom: 23px;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  font-size: 14px;
  letter-spacing: 0px;
  color: ${({ color }) => color ? color : '#999999'};
  text-align: center;
  font-family: ${MediumFont};
  height: ${({ height }) => height ? height : ''};
  > img {
  width: ${({ color,bgColor }) => color ? "185px" : bgColor ? "25px"  :"none"};
  height: 20px;
  position: relative;
   top: 11px;
  }
  > div {
    position: relative;
    width: ${({ color }) => color ? "100%" : "none"};
    bottom: 9px;
  }
`;

const LevelTextNew = styled.div`
  float: left;
  width: 100%;
  position: relative;
  bottom: ${({ bottom }) => bottom ? '30px' : '40px'};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  font-size: 12px;
  letter-spacing: 0px;
  color: ${({ color }) => color ? 'white' : 'white'};
  
  font-family: ${MediumFont};
  height: 0px;
  > img {
  width: 145px;
  position: relative;
   top: 11px;
  }
  > div {
    position: relative;
    width: ${({ color }) => color ? "100%" : "none"};
    bottom: 9px;
    bottom: 5px;
    left: 20px;
  }
`;

const AoiBarPeopleGridViewV2 = styled.div`
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: linear-gradient(360deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;

    > img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
  ${({ active }) => active && css`
     >div {
    
       }
    `}
`;
const CardContainerHearder = styled.div`
    position: absolute;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    top: 0px;
    width: 100%;
    padding: 0px;
    height: 49px;
   border-radius: 6px 6px 0px 0px;
   ${({ active }) => active && css`
    background-color: #005C87;
    `}
    
`;
const UserDetail =styled.div`
   font-family: Rubik-Regular;
   width: 100%;
   
  .employeeInfo {
    background-color: #F6F6F8;
    float: left;
    width: 100%;
    font-size: 12px;
  }

  .body_col {
    background-color: white;
    height: 90px;
    width: 100%;
    border-radius: 6px;
  }
  
  .employeeInfo1 {
  float: left;
  width:100%;
  font-size: 12px;
  padding: 10px 26px;
  }
  
  .section1 {
    color: #99A3A4;
    float: left;
    }
  .section2 {
  display: inline-block;
  float: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 71px);  
  } 
  .section3 {
  display: inline-block;
  float: left;
  }
  .section4 {
  display: inline-block;
  float: right;
  }
  
  ${({ active }) => active && css`
    .employeeInfo {
      background-color: white;
      }
    `}

    .challenge-user {
      height: 50px;
      float: left;
      text-transform: capitalize;
      position: relative;
      bottom: 13px;
      width: 100%;
      background-color: rgba(0, 92, 135, 0.05);
      margin-left: 0px;
       display: flex;
       justify-content: center;
       align-items: center;
      @media (max-width: 660px) {
        position: static;
        margin: 10px 15px;
      }
      @media (max-width: 1200px) and (min-width: 761px) {
        position: static;
        margin: 10px 15px;
      }
    }
    
    .user-info {
      display: flex;
      position: relative;
      top: 10px;
      > h6 {
        color: #67676A;
        font-size: 15px
        font-family: ${MediumFont};
        opacity: 0.5
        margin-left: 5px;
        text-transform: none;
        margin-top: 0;
      }
      > span {
        margin-left: -12px;
        > img {
          width:30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid #fff;
          background: white;
        }
      }
      .user-list {
        color: #67676A;
        font-size: 15px
        font-family: ${MediumFont};
        position: relative;
        right: 129px;
        top: 10px;
        > p {
          font-family: Rubik;
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 16px;
         display: flex;
         align-items: center;
         color: #005C87;
         mix-blend-mode: normal;
        }
      }
      }
      .circle {
        font-family: ${BoldFont}
        color: #fff;
        display: inline-block;
        padding-top: 3px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        text-align: center;
        background-color: #4798D9;
      } 
    }  
`;
const DepartmentData = styled.div`

 width: calc(100% - 10px);
    font-family: Rubik;
    font-style: normal;
   font-weight: normal;
   font-size: 14px;
   text-align: center;
   color: #005C87;
 `;

const CardContainerV2 = styled.div`
 width: calc((100% - 75px) / 4);
 border-radius: 6px;
 background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
 margin-bottom: 25px;
 margin-right: ${({marginRight}) => marginRight ? marginRight : '0'};
 border: ${({deleted,active}) => deleted?"1px solid #FD7175":active ? '1px solid #005C87' : '1px solid #005C87'};
 &:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
}
`;

const GridCard = styled.div`
width: 100%;
min-height: 330px;
border-radius: 6px;
.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 80%;
  margin-bottom 10px;
  margin-left: 50px;
  >div{
    width: 90px;
    height: 90px;
    margin: auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.name{
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    div{
      width: 32px;
    height: 32px;
    display: flex;
    // margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
      >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    }
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: rgba(0, 92, 135, 0.05);
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .no-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;

const RequestButton = styled.div`
width: 160px;
height: 40px;
display: flex;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
background: #FD7175;
background: ${({ background }) => background ? background : "#FD7175"};
margin: ${({ margin }) => margin ? margin : "auto"};
color: #fff;
justify-content: center;
align-items: center;
cursor: ${({ cursor }) => cursor ? 'auto' : "pointer"};
border-radius: 6px;
 `;


const GridCardPeople = styled.div`
width: 100%;
min-height: 100%;
border-radius: 6px;
position:relative;
display:flex;
flex-direction:column;
justify-content:space-between;
.wrapperData{
  width: 100%;
}
.imageBackground {
  position: absolute;
  ${'' /* -webkit-position: absolute; */}
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  .view-button {
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    min-width: 160px;
    height: 40px;
    background: ${({background}) => background ? background : '#FD7175'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({cursor}) => cursor ? "not-allowed" : 'pointer'};
    border-radius: 6px;
    color: white;
    text-align: center;
    padding: 0px 10px;
  }
}


.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 80%;
  margin-bottom 10px;
  margin-left: 50px;
  >div{
    width: 90px;
    height: 90px;
    margin: auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.name{
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.location{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
}
.no-location{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  height: 50px;
  background: rgba(0, 92, 135, 0.05);
  align-items: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    div{
      width: 32px;
    height: 32px;
    display: flex;
    // margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
      >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    }
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: rgba(0, 92, 135, 0.05);
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .no-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;



//  people page update styles

const CardContainerView2 = styled.div`
 width: calc((100% - 80px) / 4);
 ${'' /* width: 288px; */}
 ${'' /* height: 268px; */}
 border-radius: 6px;
 background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
 margin-bottom: 25px;
 margin-right: ${({marginRight}) => marginRight ? marginRight : '0'};
 border: ${({deleted,active}) => deleted?"1px solid #FD7175":active ? '1px solid rgba(0, 92, 135, 0.3)' : '1px solid rgba(0, 92, 135, 0.3)'};
 &:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
}
`;

const GridCardView = styled.div`
width: 100%;
min-height: 330px;
border-radius: 8px;
.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 80%;
  margin-bottom 10px;
  margin-left: 50px;
  >div{
    width: 80px;
    height: 80px;
    margin: auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.name{
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    div{
      width: 32px;
    height: 32px;
    display: flex;
    // margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
      >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    }
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: rgba(0, 92, 135, 0.05);
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .no-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;



const GridCardPeopleView = styled.div`
width:288px;
height:268px;
width: 100%;
min-height: 100%;
border-radius: 6px;
position:relative;
display:flex;
flex-direction:column;
justify-content:space-between;
.wrapperData{
  width: 100%;
}
.imageBackground {
  position: absolute;
  ${'' /* -webkit-position: absolute; */}
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 6px 6px;
  z-index:2;
  .view-button {
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    min-width: 160px;
    width: 256px;
    height: 40px;
    background: ${({background}) => background ? background : '#FD7175'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({cursor}) => cursor ? "not-allowed" : 'pointer'};
    border-radius: 6px;
    color: white;
    text-align: center;
    padding: 12px 20px;

    :hover{
  background:#005C87;
  color:#ffff;
}
  }
}


.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 80%;
  margin-bottom 10px;
  margin-left: 50px;
  >div{
    width: 80px;
    height: 80px;
    margin: 25px auto auto auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 48px;
    }
  }
}
.name{
  font-family: Rubik;
  font-weight:bold;
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform:capitalize;
}
.department{
  font-family: Rubik;
  font-size: 16px;
  font-weight:400;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.location{
  font-family: Rubik;
  font-size: 16px;
  font-weight:400;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:20px;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
}
.no-location{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#005C87;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  ${'' /* margin-bottom: 15px; */}
  justify-content: center;
  height: 50px;
  background: rgba(0, 92, 135, 0.05);
  align-items: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    div{
      width: 32px;
    height: 32px;
    display: flex;
    // margin-right: 5px;
    background: #005C87;
    border-radius: 6px;
      >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    }
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: rgba(0, 92, 135, 0.05);
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .no-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #005C87;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;



export { LevelText, Wrapper, CardContainer, ImageWrapper, NameContainer, EmployeeInformation, InformationWrapper,
  AoiBarPeopleGridViewV2,CardContainerHearder, UserDetail, DepartmentData, LevelTextNew, CardContainerV2, GridCard, RequestButton, GridCardPeople, CardContainerView2, GridCardView,GridCardPeopleView };