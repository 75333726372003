/* eslint-disable no-console,no-undef, camelcase */
import styled, { css } from 'styled-components';
const BoldFont = 'Rubik-Medium';

const EmployeeInformation = styled.div`
  margin-top: 10px;
`;

const InformationWrapper = styled.div`
  margin-top: ${({ active  }) => !active && '10px'};
  float: left;
  width: 100%;
  text-align: center;
  > span {
    font-size: 13px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
  }

  > div {
    color: rgb(153,153,153);
    font-size: 15px;
    padding: 15px 0 30px 0;
  }
  ${({ boldFont }) => boldFont & css`
    > div {
      font-family: ${BoldFont};
    }
  `}
`;

const LevelText = styled.div`
  float: left;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  font-size: 14px;
  letter-spacing: 0px;
  color: ${({ color }) => color ? color : '#999999'};
  text-transform: uppercase;
  uppercase
  font-weight: 400;
  text-align: center;
   
  > img {
    width: ${({ imgSize }) => imgSize ? "48px" : 'none'};
    height: ${({ imgSize }) => imgSize ? "65px" : 'none'};
    margin-bottom: 25px;
  }

`;

const CelebrationDate = styled.span`
  font-family: ${BoldFont};
  font-size: 16px;
  position: relative;
  left: ${({ bColor }) => bColor ? "10px" : 'none'};
  bottom: ${({ bColor }) => bColor ? "0px" : '0px'};
  color: ${({ bColor }) => bColor ? "#FD7175" : '#9FC989'};
`;

export { LevelText, EmployeeInformation, InformationWrapper, CelebrationDate };